<template>
  <div>
    <Breadcrumbs page_title="feedbackbox" :items="breadcrumbs" :item_no="item_no" />
    <v-card class="mx-3 my-5" style="border-radius:16px;">
      <v-container>
       <v-row class="ma-1 justify-space-between">
          <v-col cols="12" xs="12" sm="5" lg="3">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              style="border-radius:8px"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-1">
          <v-col>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="items"
              hide-default-footer
              :search="search"
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  color="#FF6060"
                  text
                  style="font-size: 16px;margin-top:-5px; padding-left: 0 !important;"
                  class="text-capitalize"
                  @click="checkFeedbackDelete(item)"
                ><v-icon>mdi-delete-outline</v-icon>{{$t('delete')}}</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="9" md="9" xl="10">
          <div class="d-flex text-left pl-1">
              <v-pagination circle v-model="page" class="pagination" :length="pageCount"></v-pagination>
              </div>
            </v-col>
            
            <v-col  cols="12"
            xs="12"
            sm="3"
            md="3"
            xl="2"
            class="d-flex justify-end">
              <p class="mt-3 pr-4">{{ $t("Show")}}</p>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    width="70"
                    v-bind="attrs"
                    v-on="on"
                    style="border: 1px solid #C0C0C0; opacity: 1"
                   class="text-capitalize mt-1 mr-5"
                    outlined
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in paginationCount"
                    :key="index"
                    @click="itemsPerPage = item.title"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
       
      </v-container>
    </v-card>

    <!-- Delete -->
    <v-dialog v-model="dialog" width="587">
      <v-card>
        <v-card-title
          style="font-size: 25px"
          class="justify-start"
        >{{ $t("deleteconfirm") }}</v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="
              font-size: 20px;
              color: #363636;
              opacity: 1;
              text-align: start;
            "
          >{{ $t("suredelete") }} "Feedback #{{ editData.no }}" ?</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-3"
            style="font-size: 16px; border-radius:8px;border: 1px solid #C0C0C0;"
            width="100"
            text
            @click="dialog = false"
          >{{ $t("cancel") }}</v-btn>
          <v-btn
            class="text-capitalize mb-3"
            width="100"
            style="font-size: 16px; border-radius:8px;"
            color="error"
            @click="deleteData()"
          >{{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   
    <SuccessDialog
      :show="successDialog"
      :title="$t('success')"
      :text="$t('deletefeedback')"
      @close="(successDialog = false), getData()"
    />
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="(permissionDialog = false)"
    />
  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data: () => ({
    item_no: 0,
    permissionDialog: false,
    permissionMessage: "",
    breadcrumbs: [
      {
        //image: "house.png"
        sidebar_tilte: "home"
      },
      {
        text: "feedbackbox"
      }
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    items: [],
    editData: {},
    dialog: false,
    successDialog: false,
    message: "",
    search: null,
    loading: true
  }),
  mounted() {
    this.getData();
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor"
        },
        {
          text: this.$t("title"),
          align: "left",
          value: "title",
          class: "tableColor"
        },
        {
          text: this.$t("message"),
          align: "left",
          value: "fbReceived",
          class: "tableColor"
        },
        {
          text: this.$t("employeeId"),
          align: "left",
          value: "userID",
          class: "tableColor"
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "name",
          class: "tableColor"
        },
        {
          text: this.$t("senddate"),
          value: "sendDate",
          align: "left",
          class: "tableColor"
        },
        {
          text: this.$t("Manage"),
          align: "center",
          value: "actions",
          class: "tableColor"
        }
      ];
    }
  },
  methods: {
    async checkFeedbackDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("feedbackbox");
      if (checkPermission) {
        self.dialog = true;
        self.editData = item;
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to delete Feedback Box.";
      }
    },
    prepareReply({ fbReceived, userID, title, name, email, empid }) {
      this.fbReceived = fbReceived;
      this.userID = userID;
      this.title = title;
      this.empid = empid;
      this.Name = name;
      this.Email = email;
      this.feedback = true;
    },
    async getData() {
      let self = this;
      self.loading = true;
      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.post(
          `${self.web_url}FeedBack/GetFeedback?id=` +
            localStorage.getItem("companyID") +
            `&offset=` +
            new Date().getTimezoneOffset()
        );
        self.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
          sendDate: moment(v.sendDate).format("DD/MM/YYYY HH:mm")
        }));
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Feedback Box.";
      }
      self.loading = false;
    },

    async deleteData() {
      const res = await this.$axios.post(
        `${this.web_url}FeedBack/RemoveFeedBack`,
        { id: this.editData.id }
      );
      this.message = res.data.message;
      this.successDialog = true;
      this.dialog = false;
      this.getData();
    }
  }
};
</script>
<style scoped>
/* >>> tr:nth-child(even) {
  background: #e0e0e0;
} */
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: top;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #A6CC39 !important;
  color: #FFFFFF !important;
  /* opacity: 1; */
}
</style>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: unset !important;
    margin-top: 1.5rem !important;
  }
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
</style>